import { LabelsResponse, UserStoresResponse } from "../types/Response";
import { FiraStore } from "../types/Stores";
import { CreateStoreResponse } from "../utils/response";
import {
  CreateOwnerStoreForm,
  Country,
  CreateStoreForm,
  DashboardCountingResponse,
  EditStoreProfileForm,
  FiraProduct,
  LabelProductCreate,
  FiraProductLabels,
} from "../utils/types";
import {
  ProductCreateForm,
  ProductForm,
} from "../views/DetailProductView/DetailProductView";
import {
  apiManagementPrivate,
  apiServicePrivate,
  eventSourceApiActions,
  apiBusiness,
  apiBusinessPrivate,
  apiManagement,
} from "./api";

//deprecado, no responde el nuevo type de FiraStore
// export const getStoreBy = (id: string) => {
//   return apiManagementPrivate.get<FiraStore>(`/api/v1/fira-store/${id}`);
// };
export const getStoreBy = (id: string) => {
  return apiBusinessPrivate.get<FiraStore>(
    `/v1/fira-business/store/by-id/${id}`
  );
};

export const getUserStoresService = () => {
  return apiBusinessPrivate.get<UserStoresResponse>(
    `/v1/user-service/user-stores`
  );
};

// export const getUserStoresService = (
//   storeId: string,
//   offSet: number,
//   size: number
// ) => {
//   return apiManagementPrivate.get<FiraStore[]>(
//     `/v1/user-service/all-users-store/${storeId}/${offSet}/${size}`
//   );
// };

export const getStoreProductsService = (storeId: string) => {
  return apiManagementPrivate.get(`/api/v1/fira-product/store-id/${storeId}`);
};

export const getStoreProductsGlobalService = () => {
  return apiManagementPrivate.get(`/api/v1/fira-product/all-products-global`);
};

export const getStoreProductsOwnerService = () => {
  return apiManagementPrivate.get(
    `/api/v1/fira-product/all-products-by-business-client`
  );
};
export const getStoreProductsExcelFileService = (storeId: string) => {
  return apiManagementPrivate.get(`/api/v1/fira-product/download/${storeId}`, {
    headers: {
      "Content-Disposition": "attachment; fileName=Lista de Productos.xls",
      "Content-Type": "application/vnd.ms-excel",
    },
    responseType: "arraybuffer",
  });
};

export const deleteProductsService = (
  storeId: string,
  productsToDelete: string[]
) => {
  return apiManagementPrivate.post(
    `/api/v1/fira-product/delete-list/${storeId}`,
    productsToDelete
  );
};

/* CREATE PRODUCT*/
export const createProductService = (
  product: ProductForm,
  newImages: File[] | File
) => {
  const productWithoutId = { ...product };

  // Elimina el campo "id" del objeto clonado
  delete productWithoutId.id;

  const data = new FormData();
  const json = JSON.stringify(productWithoutId);
  const blob = new Blob([json], {
    type: "application/json",
  });
  data.append("FiraProductDto", blob);

  if (newImages instanceof File) {
    data.append("files", newImages, newImages.name);
  } else {
    newImages.forEach((image) => {
      data.append("files", image, image.name);
    });
  }

  const headers = {
    "Content-Type": `multipart/form-data`,
  };

  return apiManagementPrivate.post("/api/v1/fira-product", data, { headers });
};

/* UPDATE PRODUCT SOLO INFO*/
export const updateProductService = (product: FiraProduct) => {
  return apiManagementPrivate.post(
    `/api/v1/fira-product/${product.id}`,
    product
  );
};
///api/v1/product-label-controller/create
export const createProductLabelService = (labels: LabelProductCreate) => {
  return apiManagementPrivate.post(
    `/api/v1/product-label-controller/create`,
    labels
  );
};
/** UPDATE ADD IMAGES TO PRODUCT */
export const addImagesProductService = (
  id: string,
  newImages: File[] | File
) => {
  const data = new FormData();

  if (newImages instanceof File) {
    data.append("files", newImages, newImages.name);
  } else {
    newImages.forEach((image) => {
      data.append("files", image, image.name);
    });
  }

  const headers = {
    "Content-Type": `multipart/form-data`,
  };

  return apiManagementPrivate.post(
    `/api/v1/fira-product/add-images/${id}`,
    data,
    {
      headers,
    }
  );
};
/** DELETE IMAGE */
export const deleteImagesProductService = (id: string, listImage: string[]) => {
  return apiManagementPrivate.post(
    `/api/v1/fira-product/delete-images/${id}`,
    listImage
  );
};

/** SORT IMAGE */
export const sortImagesProductService = (id: string, listImage: string[]) => {
  return apiManagementPrivate.post(
    `/api/v1/fira-product/sort-images/${id}`,
    listImage
  );
};

export const editStoreService = (id: string, body: FiraStore) => {
  return apiManagementPrivate.put(`/api/v1/fira-store/${id}`, body);
};

export const createStoreService = (body: CreateStoreForm) => {
  return apiManagementPrivate.post<CreateStoreResponse>(
    "/api/v1/fira-store/create",
    body
  );
};

//--------DEPRECATED

export const getLanguagesService = () => {
  return apiManagementPrivate.get("/api/v1/language");
};

export const getCountriesService = () => {
  return apiManagementPrivate.get<Country[]>("/api/v1/countries/all");
};

export const getCurrenciesService = () => {
  return apiManagementPrivate.get("/api/v1/currencies");
};

//--------DEPRECATED

export const getSynchronizationLink = (ecommcerce: string) => {
  return apiManagementPrivate.get(
    `v1/ecommerce/get-url-installation/${ecommcerce}`
  );
};

export const getSynchronization = (
  ecommerce: string,
  storeId: string,
  syncCode: string
) => {
  return apiManagementPrivate.get(
    `v1/ecommerce/do-sincronization/${ecommerce}/${storeId}/${syncCode}`
  );
};

// VOD Statistics
export const getVODProductsStats = (
  videoId: string,
  onSuccess: (message: MessageEvent) => void
) => {
  const eventSource = eventSourceApiActions(
    `${"v1/video-actions/product/click-by-vod/"}${videoId}`
  );
  eventSource.onmessage = (event) => {
    onSuccess(event);
  };
  eventSource.onerror = () => {
    eventSource.close();
  };
};

export const getVODStatsByCriteria = (
  criteria: string,
  videoId: string,
  onSuccess: (message: MessageEvent) => void
) => {
  const eventSource = eventSourceApiActions(
    `${"v1/video-actions/vod/stats-by-criteria/"}${criteria}${"/"}${videoId}`
  );

  eventSource.onmessage = (stats) => {
    onSuccess(stats);
  };
  eventSource.onerror = () => {
    eventSource.close();
  };
};

// OWNER STORES SERVICES

export const ownerStoreList = (
  businessClientId: string,
  limit: number = 1000000
) => {
  return apiBusinessPrivate.get<
    FiraStore[]
  >(`/v1/fira-business/store/by-owner/${businessClientId}/${0}/${limit}
  `);
};

export const ownerStoreListByStateService = (
  businessClientId: string,
  state: string,
  limit: number = 100000
) => {
  return apiBusinessPrivate.get<
    FiraStore[]
  >(`/v1/fira-business/store/by-owner-and-state/${businessClientId}/${state}/${0}/${limit}
  `);
};
// Using offset 0 and limit 100 to get all possible stores - Temporal use

export const softDeleteOwnerStoreService = (id: string, username: string) => {
  return apiBusinessPrivate.put(
    `/v1/fira-business/store/soft-delete/${id}/${username}`
  );
};

export const getAllUsersFromStore = (storeId: string) => {
  return apiBusinessPrivate.get(
    `/v1/user-service/all-users-store/${storeId}/${0}/${100}`
  );
};

export const changeUserStatusService = (
  storeId: string,
  userId: string,
  userRole: string
) => {
  const body = {
    UserId: userId,
    Role: userRole,
  };

  return apiManagementPrivate.put(
    `/v1/user-service/change-user-status/${userId}/${storeId}`,
    body
  );
};

export const getAllStoreStatsService = (
  storeId: string,
  initialDate: string,
  finalDate: string
) => {
  return apiServicePrivate.get(
    `/v1/live-broadcast-event/dashboard/broadcasting-counting/all-stats/${storeId}/${initialDate}/${finalDate}`
  );
};
export const updateOwnerStore = (id: string, body: FiraStore) => {
  return apiBusinessPrivate.put(`/v1/fira-business/store/update/${id}`, body);
};

export const createOwnerStoreService = (body: CreateOwnerStoreForm) => {
  return apiBusinessPrivate.post("/v1/fira-business/store/create", body);
};

export const getAllUsersService = (offSet: number, size: number) => {
  return apiBusinessPrivate.get(
    `/v1/user-service/get-all-users/${offSet}/${size}`
  );
};

export const getBusinessStoreByIdService = (storeId: string) => {
  return apiBusinessPrivate.get<FiraStore>(
    `/v1/fira-business/store/by-id/${storeId}`
  );
};

export const editStore = (id: string, body: FiraStore) => {
  return apiBusinessPrivate.put<FiraStore>(
    `/v1/fira-business/store/update/${id}`,
    body
  );
};
